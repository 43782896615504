import React, { FormEvent, useState } from 'react'
import { useRouter } from 'next/router'
import axios from 'axios';
import type { NextPage } from 'next'
import type { GetServerSideProps } from 'next';
import { isAuth } from '../../lib/isAuth';

interface LoginPage {}

export const getServerSideProps: GetServerSideProps = async function ({ req, res }) {
  const isAuthenticated = isAuth(req, res)
  if(isAuthenticated) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    }
  }

  return {
    props: {},
  }
}

const Login: NextPage<LoginPage> = (props) => {
  const router = useRouter()
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    try {
      const res = await axios.post('/api/auth/login', { email, password })
      if(res.status === 200) router.push('/projects')
    } catch (error) {
      // Set validation errors
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-lightgray">
      <div className="px-4 container h-screen flex flex-col items-center pt-[20%]">
        <h1 className="text-center mb-8">Lynxtool Admin</h1>
          <div className="flex flex-col p-4 md:p-8 rounded-md bg-white w-full max-w-lg drop-shadow-md border-slate-900">
            <form id="loginForm" onSubmit={(e) => handleOnSubmit(e)}>
              <div>
                <label className="mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  // className="mb-4 pt-1 h-[40px] text-lg w-full rounded bg-white px-2 text-black outline-none ring-1 ring-inset ring-purple"
                  placeholder="email"
                />
              </div>
              <div>
                <label className="mb-2">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  // className="pt-1 h-[36px] w-full rounded bg-white px-2 text-black outline-none ring-1 ring-inset ring-purple"
                  placeholder="email"
                />
              </div>
              <div className='flex justify-center'>
                <button type="submit" className='w-full sm:w-auto btn'>
                  Login
                </button>
              </div>
            </form>
          </div>
      </div>
    </div>
  )
}

export default Login
